<template>
  <div>
    <Button @click="add" v-if="!onlyForm">添加教材</Button>
    <Modal
      v-model="showModal"
      width="998"
      :mask-closable="false"
      :title="(onlyForm?'重传':'添加')+'教材'"
      @on-visible-change="$event?'':$emit('close')"
    >
      <Spin v-show="showSpin" fix>
        <div v-html="spinMsg"></div>
      </Spin>
      <div style="height:600px" v-if="showModal">
        <TeachingMaterial5lvlSelect v-if="!onlyForm" @select="teachingId=$event" class="m-b-xs fl" />
        <Button @click="selectAndHandle" class="fr">导入压缩包</Button>
        <div style="clear:both"></div>
        <template v-if="articles.length">
          <div class="article">
            <div v-for="(arc,i) in articles" :key="i" class="flex">
              <div class="p-t-md" style="margin-top:2px">{{i+1}}.</div>
              <div class="p-l-md p-t-md flex1">
                <h3>{{arc.contentTitle[0].content}}</h3>
                <div class="p-t-xs m-b-xs" v-if="'cn' === laun">
                  序号：
                  <InputNumber size="small" v-model="arc.sort" />
                </div>
                <img v-for="(pic,j) in arc.picMap[0]" :key="j" :src="pic.url" class="small-img" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <Button slot="footer" type="primary" class="m-r-md" @click="submit">提交</Button>
    </Modal>
  </div>
</template>

<script>
import teachingZipSelectAndHandle from './services/teachingZipSelectAndHandle';
import { saveTeaching } from './services/teachingService';
export default {
  components: {  },
  props: {
    onlyForm: {
      default: false
    },
    info: {}
  },
  data() {
    return {
      teachingId: '',
      articles: [],
      showModal: false,
      spinMsg: '',
      showSpin: false
    };
  },
  computed: {
    reSelectTitles() {
      return this.info ? this.info.map((ele) => ele.contentTitle[0]) : [];
    },
    laun() {
      return this.$store.getters.getLaun;
    }
  },
  methods: {
    add() {
      this.showModal = true;
      this.teachingId = '';
      this.articles = [];
    },
    async selectAndHandle() {
      const res = await teachingZipSelectAndHandle(
        (log) => {
          this.spinMsg = log;
          this.showSpin = !!log;
        },
        'cn' === this.laun
          ? undefined
          : (title) => (/Lesson\s*-?\d+\s*$/i.test(title) ? +title.replace(/^.*?Lesson\s*(-?\d+)\s*$/i, '$1') : 0),
        this.reSelectTitles
      );
      console.info('压缩包解析情况：', res);
      if (!res.result || !res.result.length) {
        this.spinMsg = `压缩包有文件(${res.unhandleableFiles.length}个)无法处理，不符合匹配规则，舍弃了${res.abandonedByTitles.length}篇文章`;
        setTimeout(() => {
          this.showSpin = false;
        }, 3000);
        return;
      }

      this.spinMsg = '即将呈现页面...';
      res.result.forEach((ele) => {
        ele.contentType = this.laun + 'Teaching';
      });

      this.articles = res.result;
      this.showSpin = false;
    },
    async _handleSubmitOne(arc, prefixMsg) {
      this.spinMsg = prefixMsg;
      // 上传图片：
      let uplodas = [];
      let imgComplete = 0;
      arc.picMap[0].forEach((imgObj) => {
        if (!imgObj.file) {
          return;
        }
        uplodas.push(
          this.$uploadService.uploadOne(imgObj.file).then(({ key, url }) => {
            arc.mdContentList[0] = arc.mdContentList[0].replace(`src="${imgObj.url}"`, `src="${url}"`);
            imgObj.key = key;
            imgObj.url = url;
            imgObj.file = null;
            imgComplete++;
            this.spinMsg = prefixMsg + `正在上传图片（${uplodas.length}/${imgComplete}）...`;
          })
        );
      });
      await Promise.all(uplodas)
        .then(() => {
          this.spinMsg = prefixMsg + `图片上传完成，正在提交...`;
          arc.pass = 9; // pass 9 提交到配音
          arc.teachingId = this.teachingId; // 同步teachingId
          return saveTeaching([arc]);
        })
        .catch((err) => {
          this.spinMsg = prefixMsg + (err ? err.toString() : '请求错误,请联系后台人员');
          return Promise.reject(this.spinMsg);
        })
        .finally(() => {
          this.$forceUpdate();
        });
    },
    async submit() {
      if (this.info && this.info.length) {
        this.articles.forEach((arc) => {
          let tt = arc.contentTitle[0].content;
          let oarc = this.info.find((ele) => tt === ele.contentTitle[0]);
          arc.parentId = oarc.id;
          arc.parentProcessId = oarc.processId;
        });
        // 编辑
        console.log(this.info, this.articles);
        // return;
      } else if (!this.teachingId) {
        // 新增
        this.$Message.warning('未选择到具体的教材');
        return;
      }

      // -----------
      let complete = 0;
      let errorTasks = [];
      this.showSpin = true;

      let doing = 0;
      for (let arc of this.articles) {
        this._handleSubmitOne(arc, `共${this.articles.length}篇文章，正在上传第${complete + doing + 1}篇...<br> &nbsp;`)
          .catch((err) => {
            errorTasks.push({
              arc,
              reason: err
            });
          })
          .finally(() => {
            complete++;
            doing--;
          });
        doing++;

        while (doing >= 8) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      while (doing > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      // -------------
      if (errorTasks.length) {
        errorTasks = errorTasks.map((ele) => {
          let path = ele.arc.contentTitle[0].txtPath;
          path = path ? path.substring(0, path.lastIndexOf('/')) : ele.arc;
          return { errorPath: path, reason: ele.reason, submitError: ele.reason.split('<br> &nbsp;')[1] };
        });
        this.spinMsg =
          '存在提交失败的文章：' +
          errorTasks.map(({ errorPath, reason }) => {
            return '<br>' + errorPath + ' : ' + reason;
          });
        console.table(errorTasks, ['errorPath', 'submitError']);
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.showSpin = false;
      this.spinMsg = '';
      this.showModal = false;
      this.articles = [];
      this.$emit('success');
    }
  },
  mounted() {
    if (this.onlyForm) {
      this.showModal = true;
    }
    // console.log(this.info);
  }
};
</script>

<style scoped>
.article {
  /* flex: 1; */
  overflow-y: auto;
  /* border:1px solid red; */
  height: 550px;
}
.small-img {
  max-height: 100px;
  max-width: 80px;
  margin-right: 4px;
  vertical-align: middle;
}
</style>