import JSZip from 'jszip'
import iconv from 'iconv-lite'


/**
 * @returns {object}
 * @param {Map<string,object>} _map 
 * @param {string} title 
 */
function getArticleObj(_map, title) {
    if (_map.has(title)) {
        return _map.get(title)
    }
    let resObj = {        
        contentTitle: [
            {
                content: title
            }
        ],
        mdContentList: [''],
        picMap: {
            "0": []
        },

        taskName: 0,
        teachingId: "1",   // 章节id 或 册id       
    }
    _map.set(title, resObj)
    return resObj
}

const ignorCharReg = /[\s(（）)\\/,。]/g
/**
 * path必须是这样的： [... / ] title / text.txt
 * @param {JSZip.JSZipObject} fileObj 
 */
async function handleTxt(_map, fileObj) {
    const paths = fileObj.name.replace(/\\/g, '/').split('/').filter(ele => !!ele);
    if (paths.length < 2) {
        throw new Error('文本的路径无法解析到具体的文章：' + fileObj.name)
    }
    const title = paths[paths.length - 2]
    const resObj = getArticleObj(_map, title)
    const txt = await fileObj.async('text')
    resObj.mdContentList[0] = txt.split('\n').map(line => {
        if (/^图\d+$/.test(line.replace(ignorCharReg, ''))) {
            line = line.replace(ignorCharReg, '')
            line = `<figure><img src="${line}"><figcaption>${line}</figcaption></figure>`
        } else {
            line = `<p>${line}</p>`
        }
        return line
    }).join('')
    resObj.contentTitle[0].txtPath = fileObj.name
}

/**
 * path必须是这样的： [... / ] title / 图片 / img.png
 * @param {JSZip.JSZipObject} fileObj 
 */
async function handleImage(_map, fileObj) {
    const paths = fileObj.name.replace(/\\/g, '/').split('/').filter(ele => !!ele);
    if (paths.length < 3) {
        throw new Error('图片的路径无法解析到具体的文章：' + fileObj.name)
    }
    const name = paths[paths.length - 1]
    const title = paths[paths.length - 3]
    const resObj = getArticleObj(_map, title)
    const blob = await fileObj.async('blob')
    const file = new File([blob], name, { type: 'image/' + name.substring(name.lastIndexOf('.') + 1) })
    const simpleName = name.substring(0, name.lastIndexOf('.')).replace(ignorCharReg, '');
    resObj.picMap[0].push({
        url: window.URL.createObjectURL(file),
        file,
        key: '',
        name,
        simpleName,
        path: fileObj.name
    })
}

/**
 * @returns {Promise<File>}
 */
function getFile() {
    return new Promise(resolve => {
        const input = document.createElement('input')
        input.type = "file"
        input.accept = ".zip"
        input.onchange = function () {
            resolve(input.files[0])
        }
        input.click()
    })
}

/** 
 * 
 * @param {(log:string)=>void} [logFn]
 * @param {(title:string)=>number} [extractSort]
 * @param {Array<string>} [onlyTitles]
 * @param {'GB2312'|'UTF-8'} [fileNameEncoding] 
 */
export default async function (logFn, extractSort, onlyTitles = [], fileNameEncoding) {
    if (!logFn || typeof logFn !== 'function') {
        logFn = () => { }
    }
    if (!extractSort || typeof extractSort !== 'function') {
        extractSort = title => Number.parseInt(title) || 0
    }
    if (fileNameEncoding !== 'GB2312' && fileNameEncoding !== 'UTF-8') {
        fileNameEncoding = 'GB2312'
    }
    // ------------1、选择文件，并解压：----------------------------------------------------    
    const zip = new JSZip()
    const file = await getFile()
    logFn('正在解压文件...')
    const { files: fileInfo } = await zip.loadAsync(file, {
        decodeFileName: function (buf) {
            return iconv.decode(buf, fileNameEncoding);
        }
    }).catch(() => {
        logFn('解压失败，请选择正确的zip压缩包(3s后关闭)')
        setTimeout(() => {
            logFn('解压失败，请选择正确的zip压缩包(2s后关闭)')
            setTimeout(() => {
                logFn('解压失败，请选择正确的zip压缩包(1s后关闭)')
                setTimeout(() => {
                    logFn()
                }, 1000);
            }, 1000);
        }, 1000);
    })

    // ------------2、解析内容，生成初步数据模型：----------------------------------------------------
    logFn('解压完成，正在统计要解析的文件...')
    const fileObjs = Array.from(Object.values(fileInfo)).filter(ele => !ele.dir)
    const reqs = []
    let handleCount = 0;
    let invalidCount = 0;
    const unhandleableFiles = []
    const handleerrorFiles = []
    const onHandled = () => {
        handleCount++
        logFn(`文件解析完成：${reqs.length} / ${handleCount}`)
    }
    const map = new Map()  // 存放 模型结果 的地方！
    fileObjs.forEach(fileObj => {
        const name = fileObj.name
        let handleFn
        if (/\.txt$/i.test(name)) {
            handleFn = handleTxt
        } else if (/(\.png)|(\.jpg)|(\.jpeg)|(\.jpe)|(\.gif)$/i.test(name)) {
            handleFn = handleImage
        } else {
            invalidCount++
            unhandleableFiles.push(name)
            return;
        }
        reqs.push(handleFn(map, fileObj).catch(err => {
            handleerrorFiles.push({
                path: name,
                error: err ? err.toString() : '解析失败'
            })
        }).then(onHandled))
    })
    if (reqs.length) {
        logFn(`统计完成，需要解析文件：${reqs.length}个。无法处理的文件：${invalidCount}个。即将开始解析...`)
        await Promise.all(reqs).then(() => {
            logFn('文件解析完成!')
        })
    } else {
        logFn('没有需要解析的文件，请检查压缩包！')
        return { result: [], abandonedByTitles: [], abandonedNoSort: [], unhandleableFiles, handleerrorFiles, picMapWarnings: [] }
    }
    // ------------3、筛选数据模型，去掉各种不需要的----------------------------------------------------
    const abandonedByTitles = []
    const abandonedNoSort = []
    const filterTitlesFlag = Array.isArray(onlyTitles) && onlyTitles.length > 0
    for (let key of map.keys()) {
        if (filterTitlesFlag && onlyTitles.indexOf(key) < 0) {
            abandonedByTitles.push(map.get(key))
            map.delete(key)
            return
        }
        map.get(key).sort = extractSort(key)

    }
    // ------------4、进一步数据模型（修正并统计 图片与文本中图片标记的对应关系）----------------------------------------------------
    const picMapWarnings = []
    let arr = Array.from(map.values())
    logFn('正在修正图片与文本的对应关系...')
    arr.forEach(arc => {
        const pics = arc.picMap[0]
        arc.picMap[0] = (arc.mdContentList[0].match(/src="(.+?)"/g) || []).map(ele =>
            ele.replace(/src="(.+?)"/g, '$1')
        ).map(hname => pics.find(picObj => picObj.simpleName === hname) || ({
            file: null,
            key: '',
            name: '',
            path: '',
            simpleName: hname,
            url: ''
        }));
        let missingImgs = []
        arc.picMap[0].forEach(picObj => {
            let ind = pics.indexOf(picObj)
            if (ind >= 0) {
                pics.splice(ind, 1)
            }
            if (!picObj.file) {
                missingImgs.push(picObj)
            }

            arc.mdContentList[0] = arc.mdContentList[0].replace(`src="${picObj.simpleName}"`, `src="${picObj.url}"`);
        });
        if (missingImgs.length || pics.length) {
            picMapWarnings.push({
                article: arc,
                missingImgs,
                unmatchImgs: pics
            })
        }
    });
    arr.sort((a, b) => a.sort - b.sort)
    return { result: arr, abandonedByTitles, abandonedNoSort, unhandleableFiles, handleerrorFiles, picMapWarnings }
}