import axios from './axios'

export function saveTeaching(data) {
    return axios({
        url: '/teachingManage/start',
        method: 'post',
        data
    })
}

/**
 * @param {string} contentType
 * @param {Array<{id, sort}>} data 
 */
export function modifySort(contentType, data) {
    return axios({
        url: `/teachingManage/saveContentSort/${contentType}`,
        method: 'put',
        data
    })
}
/**
 * 
 * @param {'waitdeal'|'history'|'published'|'recycle'} listType 
 * @param {*} params 
 */
export function getList(listType, params) {
    let p = {
        waitdeal: 'getTaskList', history: 'getHisTaskList', published: 'getPublishList', recycle: 'getDisusedList'
    }[listType]
    return axios({
        method: 'get',
        params,
        url: `/teachingManage/${p}`
    }).then(res => {
        if (listType === 'published') {
            res = res.list
            res.rows.forEach((ele) => {
                ele.contentTitle = ele.contentTitle.map((e) => e.content);
                ele.nowTaskName = 9;
            });
        }
        return res;
    })
}

